.concept-row {
    border-color: #AAA;
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    padding: 10px;
}

.purl-link {
    font-size: small;
    color: dimgrey;
    word-break: break-word;
}

.double-size {
    width:20px;
    height:20px;
}

/* overriding react-bootstrap-table-next css since we want bigger checkboxes */
.selection-input-4 {
    width:20px;
    height:20px;
}

.react-bootstrap-table td > .selection-input-4, .react-bootstrap-table th > .selection-input-4 {
    margin-top: 3px;
    margin-left: -7px;
}