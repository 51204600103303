.GuidedRuleEditor {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
}

.fact.card {
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: stretch;
}

.button-row {
    margin-top: 10px;
    display: flex;
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
}

.button-row .btn {
    align-self: flex-end;
}

.description {
    margin-top: 10px;
}

.form-group {
}
